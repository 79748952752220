import React, { useEffect, useState } from 'react';
import AsideMenu from '../../components/AsideMenu/AsideMenu';
import PublishedItems from '../../components/PublishedItems/PublishedItems';
import YourData from '../../components/YourData/YourData';
import PasswordChange from '../../components/PasswordChange/PasswordChange';
import Recipes from '../../components/Recipes/Recipes';
import Documents from '../../components/Documents/Documents';
import Notices from '../../components/Notices/Notices';
import Help from '../../components/Help/Help';
import Libary from '../../components/Libary/Libary';
import LibarySideMenu from '../../components/Libary/LibarySideMenu';
import AddRecipe from '../../components/Recipes/AddRecipe';
import AddNewItem from '../../components/PublishedItems/AddNewItem';
import RecentDownloads from '../../components/RecentDownloads/RecentDownloads';
import HistoricDownloads from '../../components/HistoricDownloads/HistoricDownloads';
import Conversations from '../../components/Conversations/Conversations';

const Main = ({ setChosenMenuItem, chosenMenuItem }) => {
	const [content, setContent] = useState(<PublishedItems />);

	const [chosenCat, setChosenCat] = useState(undefined);
	const purchases = [];

	useEffect(() => {
		switch (chosenMenuItem) {
			case 'Zamieszczone materiały': {
				setContent(<PublishedItems setChosenMenuItem={setChosenMenuItem} />);
				break;
			}
			case 'Bieżące pobrania': {
				setContent(<RecentDownloads />);
				break;
			}
			case 'Historyczne pobrania': {
				setContent(<HistoricDownloads />);
				break;
			}
			case 'Twoje dane': {
				setContent(<YourData chosenMenuItem={chosenMenuItem} />);
				break;
			}
			case 'Zmiana hasła': {
				setContent(<PasswordChange />);
				break;
			}
			case 'Dokumenty księgowe': {
				setContent(
					<Recipes
						purchases={purchases}
						setChosenMenuItem={setChosenMenuItem}
					/>
				);
				break;
			}
			case 'Dokumenty informacyjne': {
				setContent(<Documents />);
				break;
			}
			case 'Powiadomienia': {
				setContent(<Notices />);
				break;
			}
			case 'Korespondencja': {
				setContent(<Conversations />);
				break;
			}
			case 'Pomoc': {
				setContent(
					<Help
						setChosenMenuItem={setChosenMenuItem}
						chosenMenuItem={chosenMenuItem}
					/>
				);
				break;
			}
			case 'Twoja biblioteka': {
				setContent(
					<Libary chosenCat={chosenCat} setChosenCat={setChosenCat} />
				);
				break;
			}
			case 'Dodaj fakturę': {
				setContent(
					<AddRecipe
						chosenCat={chosenCat}
						setChosenCat={setChosenCat}
						setChosenMenuItem={setChosenMenuItem}
					/>
				);
				break;
			}
			case 'Dodaj materiał': {
				setContent(
					<AddNewItem
						chosenCat={chosenCat}
						setChosenCat={setChosenCat}
						setChosenMenuItem={setChosenMenuItem}
					/>
				);
				break;
			}
			default:
				setContent(<PublishedItems setChosenMenuItem={setChosenMenuItem} />);
		}
	}, [chosenMenuItem, chosenCat]);

	return (
		<main className="main">
			<section className="section">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-xlg-3">
							{chosenMenuItem !== 'Twoja biblioteka' && (
								<AsideMenu
									chosenMenuItem={chosenMenuItem}
									setChosenMenuItem={setChosenMenuItem}
								/>
							)}
							{chosenMenuItem === 'Twoja biblioteka' && (
								<LibarySideMenu
									chosenCat={chosenCat}
									setChosenCat={setChosenCat}
									chosenMenuItem={chosenMenuItem}
									setChosenMenuItem={setChosenMenuItem}
								/>
							)}
						</div>
						<div className="col-lg-9 col-xlg-9">{content}</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default Main;
