import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newMaterialActions } from '../../store/slice/newMaterialSlice';
import { useQuery } from 'react-query';
import { authorizedGet } from '../../fetchFunctions';
import { groupBy, isEmpty } from 'lodash';

const EXCLUSIVE_CODE = 'C_DC_EU';

const conditionCodes = [
	{
		name: 'U - Uproszczone warunki pobrania',
		code: 'S',
	},
	{
		name: 'C - Cyfrowa Reprodukcja Elektroniczna',
		code: 'C_DC_DER',
	},
	{
		name: 'D - Reprodukcja Drukowana',
		code: 'C_DC_PAP',
	},
	{
		name: 'K - Kampanie reklamowe w miejscach  publicznych',
		code: 'C_DC_ACP',
	},
	{
		name: 'F - Multimedialne osadzenie',
		code: 'C_DC_ME',
	},
	{
		name: 'T - Na towary przeznaczone do sprzedaży',
		code: 'C_DC_GIS',
	},
	{
		name: 'Z - Na nadruki na znaki specyficzne',
		code: 'C_DC_OSS',
	},
	{
		name: 'S - Na specyficzne szablony elektroniczne',
		code: 'C_DC_SET',
	},
	{
		name: 'P - Na plakaty do celów dekoracyjnych',
		code: 'C_DC_PDP',
	},
	{
		name: 'N - Nieograniczona Publikacja',
		code: 'C_DC_UP',
	},
	{
		name: 'M - Medialne Pobieranie',
		code: 'M_DC_M',
	},
];

const SecondStep = ({ setStep }) => {
	const dispatch = useDispatch();
	const form = useSelector((state) => state.newMaterial);
	const [isShared, setIsShared] = useState('all');
	const [clickedItem, setClickedItem] = useState(null);
	const { data: downloadConditions } = useQuery('downloadConditions', () =>
		authorizedGet('/setting_licence_pricings?pagination=false')
	);

	const downloadConditionsGrouped = useMemo(
		() =>
			groupBy(downloadConditions, (item) => {
				return item.code.split('_').slice(0, 3).join('_');
			}),
		[downloadConditions]
	);

	const [exclusiveCodes, setExclusiveCodes] = useState([]);
	const [simplifiedCodes, setSimplifiedCodes] = useState([]);
	const [exclusiveCheck, setExclusiveCheck] = useState(true);

	useEffect(() => {
		const allLicenceAttributes = Object.values(downloadConditionsGrouped)
			.flat(1)
			.filter((lic) => exclusiveCheck || !lic.code.includes(EXCLUSIVE_CODE));

		dispatch(newMaterialActions.updateLicenceAttributes(allLicenceAttributes));
	}, [isShared, dispatch, downloadConditionsGrouped]);

	useEffect(() => {
		if (!isEmpty(downloadConditionsGrouped)) {
			const exclusives = Object.entries(downloadConditionsGrouped).reduce(
				(acc, curr) => {
					if (['M_DC_EU', 'C_DC_EU'].includes(curr[0]))
						return acc.concat(curr[1]);
					return acc;
				},
				[]
			);

			setExclusiveCodes(exclusives);
			setSimplifiedCodes(() => {
				const cds = Object.entries(downloadConditionsGrouped).reduce(
					(acc, curr) => {
						if (curr[0][0] === 'S') return acc.concat(curr[1]);

						return acc;
					},
					[]
				);
				return cds;
			});

			dispatch(
				newMaterialActions.updateLicenceAttributes(
					form?.licences?.length > 0
						? form.licences.map((e) => e.settingLicencePricing)
						: form.licenceAttributes.length > 0
						? form.licenceAttributes
						: Object.values(downloadConditionsGrouped)?.flat(1)
				)
			);
		}
	}, [downloadConditions, downloadConditionsGrouped]);

	return (
		<div className="white-box__inner">
			<div className="stepper-container">
				<h3 className="medium-title">
					Określ warunki udostępniania swoich materiałów
				</h3>
				<div className="main-text wysiwyg">
					<p>
						Udostępniaj swoje materiały na wszystkie pobrania. Zezwalaj na
						pobrania z wyłącznością - pozostaw menadżerowi serwisu na dokonanie
						właściwych wyborów, takich które przyniosą Ci największe honorarium.
						Zdaj się na jego doświadczenie.
					</p>
				</div>
			</div>
			<div className="custom-radios" id="custom-radios">
				<div className="custom-radios__wrapper">
					<div className="row">
						<div className="col-md-6 flex">
							<div
								onClick={() => {
									setIsShared('all');

									const allLicenceAttributes = Object.values(
										downloadConditionsGrouped
									).flat(1);

									if (isShared) {
										dispatch(
											newMaterialActions.updateLicenceAttributes(
												form.licenceAttributes?.filter((e) =>
													e.code.includes('C_DC_EU')
												)
											)
										);
									} else {
										dispatch(
											newMaterialActions.updateLicenceAttributes(
												allLicenceAttributes
											)
										);
									}

									dispatch(
										newMaterialActions.updateForm({
											isShared: isShared === 'all' ? '' : 'all',
										})
									);
								}}
								className="custom-radios__item js-custom-radio"
							>
								<label className="checkbox" htmlFor="f-custom-1">
									<input
										type="checkbox"
										checked={isShared === 'all'}
										name="f-custom-1"
										id="f-custom-1"
										className="checkbox__input"
									/>
									<span className="checkbox__text">
										<span className="checkbox__label">
											Udostępniam na <br /> na wszystkie pobrania
										</span>
									</span>
								</label>
							</div>

							<div className="custom-radios__info">
								Udostępniając na wszystkie pobrania umożliwisz menadżerowi
								serwisu szersze pole do działania, który w zależności od
								okoliczności dokona własciwego udostępnienia, takiego które
								przyniesie nam jak największą opłatę licencyjną za pobranie a
								Tobie jak największe honorarium.
							</div>
						</div>
						<div className="col-md-6 flex">
							<div
								onClick={() => {
									dispatch(
										newMaterialActions.updateForm({
											isShared: isShared === 'custom' ? '' : 'custom',
										})
									);
									setIsShared('custom');
								}}
								className="custom-radios__item js-custom-radio"
							>
								<label className="checkbox" htmlFor="f-custom-2">
									<input
										checked={isShared === 'custom'}
										type="checkbox"
										name="f-custom-2"
										id="f-custom-2"
										className="checkbox__input"
									/>
									<span className="checkbox__text">
										<span className="checkbox__label">
											Chcę ustalić <br /> warunki udostępniania
										</span>
									</span>
								</label>
							</div>
							<div className="custom-radios__info"></div>
						</div>
					</div>
				</div>
			</div>

			{form?.isShared === 'custom' && (
				<div className="blue-box">
					<div className="blue-box__inner">
						<div className="custom-checkbox-with-accordions-container">
							<div className="accordion js-accordion">
								{conditionCodes?.map((con, i) => {
									return (
										<div
											key={con?.name}
											className={`custom-checkbox js-accordion-item ${
												con?.code === clickedItem ? 'is-active' : ''
											}`}
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<label
													className="checkbox "
													htmlFor={`f-custom-checkbox-${con?.name}`}
												>
													<input
														checked={
															con?.code === 'S'
																? form?.licenceAttributes?.some(
																		(lic) => lic.code[0] === con.code
																  )
																: downloadConditionsGrouped[con?.code]?.filter(
																		(e) =>
																			form.licenceAttributes.some(
																				(cts) => cts.code === e.code
																			)
																  )?.length > 0
														}
														type="checkbox"
														onChange={() => {
															if (con.code === 'S') {
																if (
																	form.licenceAttributes.some((lic) =>
																		simplifiedCodes.some((i) =>
																			i.code.includes(lic.code)
																		)
																	)
																) {
																	dispatch(
																		newMaterialActions.updateLicenceAttributes(
																			form.licenceAttributes.filter(
																				(lic) => lic.code[0] !== 'S'
																			)
																		)
																	);
																} else {
																	dispatch(
																		newMaterialActions.updateLicenceAttributes([
																			...form.licenceAttributes,
																			...simplifiedCodes,
																		])
																	);
																}
																return;
															}

															if (
																form.licenceAttributes.some((e) =>
																	e.code.includes(con.code)
																)
															)
																dispatch(
																	newMaterialActions.updateLicenceAttributes([
																		...form.licenceAttributes.filter(
																			(e) => !e.code.includes(con.code)
																		),
																	])
																);
															else {
																const prevCodes = Object.entries(
																	downloadConditionsGrouped
																)?.filter((e) => e[0].includes(con.code));
																dispatch(
																	newMaterialActions.updateLicenceAttributes([
																		...form.licenceAttributes,
																		...prevCodes[0][1],
																	])
																);
															}
														}}
														name={`f-custom-checkbox-${con?.name}`}
														id={`f-custom-checkbox-${con?.name}`}
														className="checkbox__input"
													/>
													<span className="checkbox__text custom js-accordion-title">
														<span className="checkbox__label custom">
															{con?.name}{' '}
															{downloadConditionsGrouped[con?.code]?.length > 0
																? `(${
																		downloadConditionsGrouped[
																			con?.code
																		]?.filter((e) =>
																			form.licenceAttributes.some(
																				(cts) => cts.code === e.code
																			)
																		)?.length
																  }/${
																		downloadConditionsGrouped[con?.code]?.length
																  })`
																: null}
														</span>
													</span>
												</label>
												{con.code !== 'S' && (
													<span
														onClick={({ target }) => {
															setClickedItem((prev) => {
																if (prev === con.code) {
																	return null;
																}
																return con.code;
															});

															setTimeout(() => {
																target.scrollIntoView({
																	block: 'center',
																	behavior: 'smooth',
																});
															}, 10);
														}}
														style={{
															cursor: 'pointer',
															position: 'relative',
															marginLeft: 'auto',
															marginRight: 0,
															fontSize: 10,
															transition: 'all 0.3s ease-out 0s',
															transform:
																con.code === clickedItem
																	? 'rotate(180deg)'
																	: '',
														}}
														className="arrow"
													>
														<i className="ico ico-chevron-down-proj"></i>
													</span>
												)}
											</div>

											{con.code === clickedItem && (
												<div
													className="accordion__content js-accordion-content"
													style={{ display: 'block' }}
												>
													<div className="accordion__content-inner">
														{downloadConditionsGrouped[con?.code]?.map(
															(sub, i) => {
																const checked = form.licenceAttributes.some(
																	(code) => code.code === sub.code
																);

																return (
																	<div className="form__checkbox">
																		<label
																			className="checkbox"
																			htmlFor={`f-horizontal-${sub.title}`}
																		>
																			<input
																				checked={checked}
																				onChange={() => {
																					const updated = checked
																						? form.licenceAttributes.filter(
																								(lic) => lic.code !== sub.code
																						  )
																						: [...form.licenceAttributes, sub];
																					dispatch(
																						newMaterialActions.updateLicenceAttributes(
																							updated
																						)
																					);
																				}}
																				type="checkbox"
																				name={`f-horizontal-${sub.title}`}
																				id={`f-horizontal-${sub.title}`}
																				className="checkbox__input "
																			/>
																			<span className="checkbox__text">
																				<span className="checkbox__label">
																					{i + 1 + ' - ' + sub.title}
																				</span>
																			</span>
																		</label>
																	</div>
																);
															}
														)}
													</div>
												</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			)}

			<h4 className="medium-title">
				Określ, czy Twój materiał może być pobrany z wyłącznością na użytkowanie
			</h4>
			<div className="main-text wysiwyg">
				<p>
					Zezwalając na pobranie z wyłącznością na użytkowanie Twojego materiału
					umożliwiasz Pobierającemu zablokowanie tego materiału dla kolejnych
					pobrań, jednak my za takie wykorzystanie obciąymy go opłatą licencyjną
					mogącą przekroczyć kilkasetkrotnie kwotę opłaty jaką uiściłby za
					pobranie bez wyłączności, co przełoży się u Ciebie na kilkusetkrotnie
					większe honorarium.
				</p>
			</div>

			<div className="row">
				<div className="col-md-6">
					<div
						onClick={() => {
							setExclusiveCheck((prevState) => !prevState);
							const cds = form.licenceAttributes.some((e) =>
								exclusiveCodes.some((i) => i.code.includes(e.code))
							)
								? form.licenceAttributes.filter(
										(e) => !exclusiveCodes.some((i) => i.code.includes(e.code))
								  )
								: [...form.licenceAttributes, ...exclusiveCodes];

							dispatch(newMaterialActions.updateLicenceAttributes(cds));
						}}
						className="custom-radios__item margin-clear js-custom-radio"
					>
						<label className="checkbox" htmlFor="f-custom-3">
							<input
								checked={exclusiveCheck}
								type="checkbox"
								name="f-custom-3"
								id="f-custom-3"
								className="checkbox__input js-checkbox"
							/>
							<span className="checkbox__text">
								<span className="checkbox__label">
									Zezwalam na pobranie <br /> z wyłącznością
								</span>
							</span>
						</label>
					</div>

					<div className="form__actions mt-40">
						<button
							onClick={() => setStep((prev) => prev + 1)}
							type="button"
							className="btn btn--block"
						>
							Przejdź dalej
						</button>
					</div>
				</div>
			</div>

			<a
				onClick={(e) => {
					e.preventDefault();
					setStep((prev) => prev - 1);
				}}
				href="/"
				className="return-button mt-40"
			>
				<i className="ico ico-long-arrow-left-icon"></i>{' '}
				<span>Powrót do opisu</span>
			</a>
		</div>
	);
};

export default SecondStep;
