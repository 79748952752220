import React, { useMemo, useState } from 'react';
import { Flex, Loader } from '@mantine/core';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getHistoricDues } from '../../api/get';
import Row from './Row';
import {
	formatDateForInput,
	formatDateForPresentation,
	formatPrice,
} from '../../utils';

const HistoricDownloads = () => {
	const user = useSelector((state) => state.auth.user);

	const [selectedDue, setSelectedDue] = useState(null);

	const date = useMemo(() => {
		let today = new Date();
		if (today.getDate() > 15) today.setDate(15);
		else today.setDate(1);

		return formatDateForInput(today);
	}, []);

	const duesQuery = useQuery(
		['publisher_dues', user.userId, date],
		getHistoricDues,
		{
			enabled: !!user.userId,
		}
	);

	return (
		<>
			<div className="white-box white-box--selectors">
				<Flex>
					<div className="white-box__inner">
						<h2 className="main-title main-title--25 hidden-md">
							Pobrania historyczne
						</h2>
						<div className="main-text wysiwyg">
							<p style={{ marginBottom: 0 }}>
								Poniżej znajdują się pobrania Twoich materiałów z poprzednich
								okresów rozliczeniowych.
							</p>
						</div>
					</div>
					{!!selectedDue && (
						<div
							className="white-box__inner"
							style={{
								width: 'unset',
							}}
						>
							<a
								onClick={() => setSelectedDue(null)}
								href="#"
								className="btn btn--outline"
							>
								Wróć
							</a>
						</div>
					)}
				</Flex>
			</div>
			{selectedDue ? (
				<div className="white-box">
					<div className="white-box__inner">
						<div className="uploaded-materials-table-wrapper">
							{!selectedDue.purchasedItems.length ? (
								<>
									<p style={{ margin: 0, padding: 20 }}>
										Brak historycznych materiałów
									</p>
								</>
							) : (
								<table className="uploaded-materials-table">
									<thead>
										<tr>
											<th className="uploaded-materials-table__th">
												MINIATURKA
											</th>
											<th className="uploaded-materials-table__th">
												DATA POBRANIA
											</th>
											<th className="uploaded-materials-table__th">
												NR ZAPISU POBRANIA
											</th>
											<th className="uploaded-materials-table__th">
												KOD POBRANIA
											</th>
											<th className="uploaded-materials-table__th">
												NR ZESTAWU OBRAZÓW
											</th>
											<th className="uploaded-materials-table__th">
												NR OBRAZU Z ZESTAWU
											</th>
											<th className="uploaded-materials-table__th">
												KWOTA BRUTTO Z POBRANIA
											</th>
										</tr>
									</thead>
									<tbody>
										{selectedDue.purchasedItems.map((item) => (
											<Row key={item.id} item={item} />
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className="white-box">
					<div className="white-box__inner">
						<div className="uploaded-materials-table-wrapper">
							{!duesQuery.data?.length && !duesQuery.isLoading && (
								<>
									<p style={{ margin: 0, padding: 20 }}>
										Brak historycznych materiałów
									</p>
								</>
							)}
							{duesQuery.isLoading && (
								<div
									style={{
										padding: 50,
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Loader />
								</div>
							)}

							{!!duesQuery.data?.length && (
								<table className="uploaded-materials-table">
									<thead>
										<tr>
											<th className="uploaded-materials-table__th">
												Nr zestawu historycznego
											</th>
											<th className="uploaded-materials-table__th">
												Data pierwszego pobrania w zestawie
											</th>
											<th className="uploaded-materials-table__th">
												Data ostatniego pobrania w zestawie
											</th>
											<th className="uploaded-materials-table__th">
												Kwota brutto pobrań z podliczenia zestawu
											</th>
										</tr>
									</thead>
									<tbody>
										{duesQuery.data?.map((item) => (
											<tr
												key={item.id}
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													setSelectedDue(item);
												}}
											>
												<td className="uploaded-materials-table__td  td-picture">
													{item.publisherInvoices
														.map((inv) => inv.insideId.slice(5))
														.join(', ')}
												</td>
												<td className="uploaded-materials-table__td td-number">
													{formatDateForPresentation(
														item.purchasedItems[0].createdAt
													)}
												</td>
												<td className="uploaded-materials-table__td td-number">
													{formatDateForPresentation(
														item.purchasedItems.at(-1).createdAt
													)}
												</td>
												<td className="uploaded-materials-table__td td-id">
													{formatPrice(item.amount / 100)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default HistoricDownloads;
