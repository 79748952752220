import React, {useState} from 'react';
import {motion} from "framer-motion";
import { Modal } from '@mantine/core';
import {useMutation} from "react-query";
import {authorizedPost} from "../../fetchFunctions";
import {useDispatch, useSelector} from "react-redux";
import {libraryActions} from "../../store/slice/librarySlice";

const LibarySideMenu = ({chosenCat, setChosenCat}) => {
    const [opened, setOpened] = useState(false);
    const [newCategory, setNewCategory] = useState("");
    const categories = useSelector(state => state.library.categories);
    const dispatch = useDispatch();

    const addNewCategoryMutation = useMutation(authorizedPost, {
        onSuccess: () => {
            setTimeout(()=> {
                setOpened(false)
            },1000)
        }
    })

    return (
        <>
            <motion.aside
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: .3
                    }
                }}
                className="sidenav">
                <div className="sidenav-container">
                    <a
                        onClick={() => {
                            setOpened(true)
                        }}
                        className="sidenav__item sidenav__item--add-category btn btn--primary">+ Dodaj
                        kategorię</a>
                    {categories?.map(cat => (
                        <motion.a
                            initial={{opacity: 0, y: 5}}
                            animate={{opacity: 1, y: 0}}
                            transition={{
                                default: {
                                    duration: .3
                                }
                            }}
                        className={`sidenav__item btn ${chosenCat !== cat ? "btn--outline" : ""}`}
                        onClick={()=> setChosenCat(cat)}
                        >{cat.title}</motion.a>
                    ))}

                    {/*<div className="sidenav__info">*/}
                    {/*    Posiadasz bibliotekę poza*/}
                    {/*    swoim kontem?*/}
                    {/*</div>*/}
                    {/*<a className="sidenav__item btn btn--outline-nb">*/}
                    {/*    Przenieś obraz*/}
                    {/*    z innej biblioteki*/}
                    {/*</a>*/}
                </div>
            </motion.aside>
            <Modal
                opened={opened}
                withCloseButton={false}
                centered
                onClose={() => setOpened(false)}
                size="35%"
            >
                <div className="modal fancybox-content" id="category-modal" style={{display: "inline-block"}}>
                    <a className="modal__close js-modal-close"><span>Zamknij</span><i
                        className="ico ico-cancel"></i></a>
                    <div className="modal__inner">
                        <h3 className="modal__title">Dodaj kategorię</h3>
                        <form action="test" method="post" className="form js-validate" noValidate="novalidate">
                            <input
                                onChange={(e)=>{
                                    setNewCategory( e.target.value)
                                }}
                                type="text" name="f-cat" placeholder="Wpisz nazwę kategorii"
                                   className="form__input required" id="f-cat"/>
                        </form>
                        <a
                            onClick={() => {
                                dispatch(libraryActions.addCategory({
                                    title: newCategory,
                                    id: (Math.random() *10)
                                }))
                                setOpened(false)
                                addNewCategoryMutation.mutate({
                                    url:"/image_group_categories",
                                    data : {
                                        name: newCategory,
                                    }
                                })
                            }}
                            className="sidenav__item sidenav__item--add-category btn btn--primary">+ Dodaj
                            kategorię</a>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default LibarySideMenu;