export const authorizedGet = async (url, headers) => {
	const accessToken = localStorage.getItem('token');
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				...headers,
			},
		});

		if (response.status === 401) {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/auth/refresh_token`,
				{
					method: 'POST',
					body: JSON.stringify({
						refresh_token: refreshToken,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 401) {
				localStorage.removeItem('isLoggedIn');
				localStorage.removeItem('token');
				localStorage.removeItem('refreshToken');
			}

			const data = await response.json();
			if (data) {
				localStorage.removeItem('token');
				localStorage.removeItem('refreshToken');
				localStorage.setItem('token', data.token);
				localStorage.setItem('refreshToken', data.refresh_token);
			}
		}
		if (!response.ok) {
			throw new Error('Coś poszło nie tak');
		}

		return await response.json();
	} catch (e) {
		console.error(e);
	}
};

export const authorizedGetById = async (url, id = '') => {
	const accessToken = localStorage.getItem('token');
	try {
		if (id) {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}${url}/${id}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			if (!response.ok) {
				throw new Error('Coś poszło nie tak klient');
			}

			return await response.json();
		} else {
			throw new Error('Coś poszło nie tak klient');
		}
	} catch (e) {
		console.error(e);
	}
};
export const authorizedPost = async ({ url, data }) => {
	const accessToken = localStorage.getItem('token');

	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (!response.ok) {
		const error = await response.json();
		throw new Error(error.detail);
	}
	return await response.json();
};

export const authorizedDataPost = async ({ url, data }) => {
	const accessToken = localStorage.getItem('token');

	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
		method: 'POST',
		body: data,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Coś poszło nie tak');
	}

	return await response.json();
};

export const authorizedImageGroupDataPost = async ({
	url,
	data,
	imageGroupData,
	orderImage,
}) => {
	const accessToken = localStorage.getItem('token');

	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
		method: 'POST',
		body: data,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Coś poszło nie tak');
	}

	const imageData = await response.json();
	if (imageData) {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/images`, {
			method: 'POST',
			body: JSON.stringify({
				originalImage: imageData.id,
				imageGroup: imageGroupData.id,
				orderImage,
			}),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (!response.ok) {
			const error = await response.json();
			throw new Error(error.detail);
		}
		return await response.json();
	}
};

export const authorizedPatchWithId = async ({ url, data }) => {
	const accessToken = localStorage.getItem('token');
	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
			method: 'PATCH',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/merge-patch+json',
				Authorization: `Bearer ${accessToken}`,
			},
		});
		if (!response.ok) {
			throw new Error('Coś poszło nie tak');
		}

		return await response.json();
	} catch (e) {
		throw new Error('Coś poszło nie tak');
	}
};

export const authorizedPutWithId = async ({ url, data, id }) => {
	const accessToken = localStorage.getItem('token');

	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}/${id}`, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Coś poszło nie tak');
	}

	return await response.json();
};
export const authorizedDeleteWithId = async ({ url, id }) => {
	const accessToken = localStorage.getItem('token');

	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}/${id}`, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Coś poszło nie tak');
	}

	return await response.json();
};
