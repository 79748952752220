import { authorizedFetch } from './authorizedFetch';

export const changeMail = async (payload) => {
	const response = await authorizedFetch(
		`publishers/${payload.id}/change_email`,
		{
			method: 'PATCH',
			body: JSON.stringify({ newEmailAwaitingConfirm: payload.email }),
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		},
		true
	);

	if (!response.ok) {
		throw new Error();
	}

	return await response.json();
};
