import React, { useEffect, useState } from 'react';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import PublishedItems from './PublishedItems';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import Summary from './Summary';

const AddNewItem = ({ setChosenMenuItem }) => {
	const [step, setStep] = useState(1);
	const [content, setContent] = useState(<FirstStep setStep={setStep} />);

	useEffect(() => {
		switch (step) {
			case 1: {
				setContent(<FirstStep setStep={setStep} />);
				break;
			}
			case 2: {
				setContent(<SecondStep setStep={setStep} />);
				break;
			}
			case 3: {
				setContent(<ThirdStep setStep={setStep} />);
				break;
			}
			case 4: {
				setContent(
					<FourthStep setChosenMenuItem={setChosenMenuItem} setStep={setStep} />
				);
				break;
			}
			case 5: {
				setContent(<Summary setStep={setStep} />);
				break;
			}
			default:
				return;
		}
	}, [step]);

	return (
		<div className="white-box white-box--library">
			<div className="white-box__inner">
				<div className="stepper-container">
					{step < 5 && (
						<>
							<h2 className="main-title main-title--25 main-title--mb-45">
								Dodaj materiał
							</h2>
							<div className="stepper">
								<div
									className={`stepper__item ${
										step >= 1 && 'active'
									}  js-tooltip tooltipstered`}
								>
									<div className="stepper__step">1</div>
									<div className="stepper__title">Opisz zestaw</div>
								</div>
								<div className="stepper__divider">
									<i className="ico ico-long-arrow-right-black-icon"></i>
								</div>
								<div
									className={`stepper__item ${
										step >= 2 && 'active'
									}  js-tooltip tooltipstered`}
								>
									<div className="stepper__step">2</div>
									<div className="stepper__title">
										Dopuść warunki udostępnienia
									</div>
								</div>
								<div className="stepper__divider">
									<i className="ico ico-long-arrow-right-black-icon"></i>
								</div>
								<div
									className={`stepper__item ${
										step >= 3 && 'active'
									}  js-tooltip tooltipstered`}
								>
									<div className="stepper__step">3</div>
									<div className="stepper__title">
										Zaproponuj miejsce zamieszczenia
									</div>
								</div>
								<div className="stepper__divider">
									<i className="ico ico-long-arrow-right-black-icon"></i>
								</div>
								<div
									className={`stepper__item ${
										step === 4 && 'active'
									}  js-tooltip tooltipstered`}
								>
									<div className="stepper__step">4</div>
									<div className="stepper__title">
										Określ bazową opłatę licencyjną
									</div>
								</div>
							</div>
						</>
					)}

					<div className="row">{content}</div>
				</div>
			</div>
		</div>
	);
};

export default AddNewItem;
