import React from 'react';
import { formatDateForPresentation, formatPrice } from '../../utils';

const Row = ({ item }) => {
	return (
		<tr>
			<td className="uploaded-materials-table__td  td-picture">
				<img
					src={item.image.thumbnailWithoutWatermarkImage.contentUrl}
					width={100}
				/>
			</td>
			<td className="uploaded-materials-table__td td-number">
				{formatDateForPresentation(item.createdAt)}
			</td>
			<td className="uploaded-materials-table__td td-id">
				{item.downloadCode}
			</td>
			<td className="uploaded-materials-table__td td-id">{item.licenceCode}</td>
			<td className="uploaded-materials-table__td td-info">
				{item.image.imageGroup.insideId}
			</td>
			<td className="uploaded-materials-table__td td-info">
				{item.image.orderImage}
			</td>
			<td className="uploaded-materials-table__td td-info">
				{formatPrice(item.finalPrice / 100)}
			</td>
		</tr>
	);
};

export default Row;
