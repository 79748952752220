import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Modal, Select, MultiSelect } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { newMaterialActions } from '../../store/slice/newMaterialSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { authorizedGet, authorizedPost } from '../../fetchFunctions';

const ThirdStep = ({ setStep }) => {
	const dispatch = useDispatch();
	const client = useQueryClient();
	const user = useSelector((state) => state.auth.user);
	const [openAuthorAdd, setOpenAuthorAdd] = useState(false);
	const [openAuthorTopicAdd, setOpenAuthorTopicAdd] = useState(false);
	const [newAuthor, setNewAuthor] = useState(null);
	const [newTopic, setNewTopic] = useState(null);
	const [chosenAuthor, setChosenAuthor] = useState(null);
	const form = useSelector((state) => state.newMaterial);

	const { data: imageGroupCategory } = useQuery(
		'image_group_categories',
		() => authorizedGet('/image_group_categories?exists[parentCategory]=false'),
		{
			placeholderData: [],
		}
	);

	const [chosenCategory, setChosenCategory] = useState({
		subCategories: [],
	});
	const { data: chosenImageGroupsCategories, isLoading } = useQuery(
		['image_group_categories2', chosenCategory],
		() =>
			authorizedGet(
				`/image_group_categories?exists[parentCategory]=true&parentCategory=${chosenCategory.id}`
			),
		{
			placeholderData: [],
		}
	);
	const { data: imageGroupTopic } = useQuery(
		'image_group_topics',
		() => authorizedGet('/image_group_topics'),
		{
			placeholderData: [],
		}
	);

	const imageGroupAuthorsMutation = useMutation(authorizedPost);
	const imageGroupAuthorsTopicMutation = useMutation(authorizedPost);

	useEffect(() => {
		if (chosenCategory.subCategories.length > 0) {
			const findImageGGrouCAtegory = imageGroupCategory.find(
				(i) => i.id === chosenCategory.id
			);
			setChosenCategory(findImageGGrouCAtegory);
		}
	}, [imageGroupCategory]);

	useEffect(() => {
		if (form?.mainCategory) {
			setChosenCategory(
				imageGroupCategory.find((i) => i.id === form?.mainCategory?.id)
			);
			setChosenAuthor(form?.mainCategory?.id);
		}
	}, []);

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<h3 className="medium-title medium-title--mb-40">
					Zaproponuj miejsce zamieszczenia materiału
				</h3>

				<form className="form form--limited-with-buttons js-validate">
					<div className="form__group">
						<div className="form__field">
							<div className="select">
								<Select
									className={'f-sort'}
									onChange={(e) => {
										setChosenAuthor(null);
										setChosenCategory(
											imageGroupCategory.find((i) => i.id === e)
										);
										dispatch(
											newMaterialActions.updateForm({
												mainCategory: imageGroupCategory.find(
													(i) => i.id === e
												),
											})
										);
										dispatch(
											newMaterialActions.updateForm({ categories: null })
										);
									}}
									value={form?.mainCategory?.id}
									placeholder="Wybierz kategorię"
									data={imageGroupCategory?.map((igc) => {
										return {
											value: igc.id,
											label: igc.name,
										};
									})}
									rightSection={<IconChevronDown size={10} />}
									rightSectionWidth={27}
									withinPortal={true}
									styles={(theme) => ({
										rightSection: {
											pointerEvents: 'none',
											marginRight: 20,
											'& svg': {
												strokeWidth: 1,
											},
										},
										input: {
											backgroundColor: '#fff',
											border: '2px solid #BCD3E5',
											borderRadius: 0,
											transition: 'all 0.3s ease-out 0s',
											height: 57,
											userSelect: 'none',
										},
										item: {
											// applies styles to selected item
											'&[data-selected]': {
												backgroundColor: '#d0dae2',
												'&, &:hover': {
													backgroundColor: '#d0dae2',
													color: '#fff',
												},
											},

											// applies styles to hovered item (with mouse or keyboard)
											'&[data-hovered]': {
												backgroundColor: '#364a59',
												color: '#fff',
											},
										},
									})}
								/>
							</div>
						</div>
					</div>

					{form.mainCategory && (
						<div className="form__group">
							<div className="form__field">
								<div className="select">
									<Select
										className={'f-sort'}
										onChange={(e) => {
											setChosenAuthor(e);
											dispatch(
												newMaterialActions.updateForm({ categories: e })
											);
										}}
										value={form?.categories}
										placeholder={
											chosenCategory?.name === 'Kolekcje'
												? 'Wybierz autora'
												: 'Wybierz grupę'
										}
										data={
											chosenCategory?.name === 'Kolekcje'
												? chosenImageGroupsCategories
														.filter((e) => e.name !== 'Wszystkie')
														.map((sC) => {
															return {
																value: sC.id,
																label: sC.name,
															};
														})
												: chosenCategory?.subCategories
														?.filter((e) => e.name !== 'Wszystkie')
														?.map((sC) => {
															return {
																value: sC.id,
																label: sC.name,
															};
														})
										}
										rightSection={<IconChevronDown size={10} />}
										rightSectionWidth={27}
										right
										styles={(theme) => ({
											rightSection: {
												pointerEvents: 'none',
												marginRight: 20,
												'& svg': {
													strokeWidth: 1,
												},
											},
											input: {
												backgroundColor: '#fff',
												border: '2px solid #BCD3E5',
												borderRadius: 0,
												transition: 'all 0.3s ease-out 0s',
												height: 57,
											},
											item: {
												// applies styles to selected item
												'&[data-selected]': {
													backgroundColor: '#d0dae2',
													'&, &:hover': {
														backgroundColor: '#d0dae2',
														color: '#fff',
													},
												},

												// applies styles to hovered item (with mouse or keyboard)
												'&[data-hovered]': {
													backgroundColor: '#364a59',
													color: '#fff',
												},
											},
										})}
									/>
								</div>
							</div>
							{!isLoading &&
								!imageGroupAuthorsMutation.isLoading &&
								chosenCategory?.name === 'Kolekcje' &&
								chosenImageGroupsCategories?.filter(
									(e) => e.name !== 'Wszystkie'
								).length < 2 && (
									<button
										disabled={isLoading && imageGroupAuthorsMutation.isLoading}
										onClick={() => setOpenAuthorAdd(true)}
										type="button"
										className="form-add-button"
									>
										+ Dodaj nowego autora
									</button>
								)}
						</div>
					)}

					{form?.mainCategory?.name === 'Kolekcje' && chosenAuthor && (
						<div className="form__group">
							<div className="form__field">
								<div className="select">
									<Select
										value={form?.topic?.split('/')[3]}
										className={'f-sort'}
										onChange={(e) => {
											dispatch(newMaterialActions.updateForm({ topic: e }));
										}}
										placeholder="Wybierz temat"
										data={imageGroupTopic?.map((y) => {
											return {
												value: y.id,
												label: y.name,
											};
										})}
										rightSection={<IconChevronDown size={10} />}
										rightSectionWidth={27}
										styles={(theme) => ({
											rightSection: {
												pointerEvents: 'none',
												marginRight: 20,
												'& svg': {
													strokeWidth: 1,
												},
											},
											input: {
												backgroundColor: '#fff',
												border: '2px solid #BCD3E5',
												borderRadius: 0,
												transition: 'all 0.3s ease-out 0s',
												height: 57,
											},
											item: {
												// applies styles to selected item
												'&[data-selected]': {
													backgroundColor: '#d0dae2',
													'&, &:hover': {
														backgroundColor: '#d0dae2',
														color: '#fff',
													},
												},

												// applies styles to hovered item (with mouse or keyboard)
												'&[data-hovered]': {
													backgroundColor: '#364a59',
													color: '#fff',
												},
											},
										})}
									/>
								</div>
							</div>
							{imageGroupTopic.length < 5 && (
								<button
									type="button"
									onClick={() => setOpenAuthorTopicAdd(true)}
									className="form-add-button"
								>
									+ Dodaj temat
								</button>
							)}
						</div>
					)}

					<div className="form__actions mt-30">
						<button
							onClick={() => setStep((prev) => prev + 1)}
							type="button"
							className="btn btn--block"
						>
							Przejdź dalej
						</button>
					</div>
				</form>

				<a
					onClick={(e) => {
						e.preventDefault();
						setStep((prev) => prev - 1);
					}}
					href="/"
					className="return-button mt-40"
				>
					<i className="ico ico-long-arrow-left-icon"></i>{' '}
					<span>Powrót do warunków udostępnienia</span>
				</a>
			</div>
			<Modal
				opened={openAuthorAdd}
				withCloseButton={false}
				centered
				onClose={() => setOpenAuthorAdd(false)}
				size="35%"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							setOpenAuthorAdd(false);
						}}
						className="modal__close js-modal-close"
					>
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">Dodaj autora</h3>
						<form
							action="test"
							method="post"
							className="form js-validate"
							noValidate="novalidate"
						>
							<input
								onChange={(e) => setNewAuthor(e.target.value)}
								type="text"
								name="f-aurora"
								placeholder="Wpisz"
								className="form__input required"
								id="f-aurora"
							/>
						</form>
						<div className="form__actions">
							<button
								onClick={() => {
									if (
										chosenImageGroupsCategories?.filter(
											(e) => e.name !== 'Wszystkie'
										)?.length < 2
									) {
										imageGroupAuthorsMutation.mutate(
											{
												url: '/image_group_categories',
												data: {
													name: newAuthor,
													description: 'Obrazy kolekcyjne - ' + newAuthor,
													isDefaultSubMainCategory: false,
													parentCategory: chosenCategory.id,
												},
											},
											{
												onSuccess: (d) => {
													client.invalidateQueries('image_group_categories');
													client.invalidateQueries('image_group_categories2');
													setChosenCategory(
														imageGroupCategory.find(
															(i) => i.id === d.parentCategory.id
														)
													);
													setOpenAuthorAdd(false);
												},
											}
										);
									}
								}}
								type="button"
								disabled={
									imageGroupAuthorsMutation.isLoading ||
									chosenImageGroupsCategories?.filter(
										(e) => e.name !== 'Wszystkie'
									)?.length === 2
								}
								className="btn btn--block"
							>
								Dodaj
							</button>
						</div>
						{chosenImageGroupsCategories?.filter((e) => e.name !== 'Wszystkie')
							?.length === 2 && (
							<p style={{ color: 'red', marginTop: 20 }}>
								Nie można dodać autora (Osiągnięto maksymalną liczbę autorów: 2)
							</p>
						)}
					</div>
				</div>
			</Modal>
			<Modal
				opened={openAuthorTopicAdd}
				withCloseButton={false}
				centered
				onClose={() => setOpenAuthorTopicAdd(false)}
				size="35%"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							setOpenAuthorTopicAdd(false);
						}}
						className="modal__close js-modal-close"
					>
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">Dodaj temat</h3>
						<form
							action="test"
							method="post"
							className="form js-validate"
							noValidate="novalidate"
						>
							<input
								onChange={(e) => setNewTopic(e.target.value)}
								type="text"
								name="f-aurora"
								placeholder="Wpisz"
								className="form__input required"
								id="f-aurora"
							/>
						</form>
						<div className="form__actions">
							<button
								onClick={() => {
									imageGroupAuthorsTopicMutation.mutate(
										{
											url: '/image_group_topics',
											data: {
												name: newTopic,
												publisher: user.userId,
												category: chosenAuthor.id,
											},
										},
										{
											onSuccess: (d) => {
												client.invalidateQueries('image_group_topics');
												setOpenAuthorTopicAdd(false);
											},
										}
									);
								}}
								type="button"
								disabled={imageGroupAuthorsTopicMutation.isLoading}
								className="btn btn--block"
							>
								Dodaj
							</button>
							{imageGroupAuthorsTopicMutation.isError && (
								<p style={{ color: 'red', marginTop: 20 }}>
									Nie można dodać tematu (Osiągnięto maksymalną liczbe tematów:
									5)
								</p>
							)}
						</div>
					</div>
				</div>
			</Modal>
		</motion.div>
	);
};

export default ThirdStep;
