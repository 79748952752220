import React, { useEffect, useState } from 'react';
import { Loader, Pagination, Select } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { getImageCategories, getImages } from '../../api/get';
import ImageGroupRow from './ImageGroupRow';
import { authorizedFetch } from '../../api/authorizedFetch';

const PER_PAGE = 10;
const CATEGORY_ALL = [
	'1ed17c94-271f-678e-b474-834af8ea4327',
	'1ed17c94-2721-6e94-8bfd-834af8ea4327',
	'1ed17c94-2723-66d6-b752-834af8ea4327',
];

const PublishedItems = ({ setChosenMenuItem }) => {
	const queryClient = useQueryClient();

	const user = useSelector((state) => state.auth.user);

	const [simplesearch, setSimplesearch] = useState('');

	const [sortType, setSortType] = useState('desc');

	const [chosenCategory, setChosenCategory] = useState('');
	const [page, setPage] = useState(1);

	const imageCategoriesQuery = useQuery(
		['image_group_categories'],
		getImageCategories,
		{
			initialData: [],
			select: (data) =>
				data.reduce((acc, curr) => {
					for (const cat of curr.subCategories) {
						acc.push({
							value: cat.id,
							label: cat.name,
							group: curr.name,
						});
					}
					return acc;
				}, []),
		}
	);

	const imagesQuery = useQuery(
		['images', { page, simplesearch, chosenCategory, sortType }],
		getImages,
		{
			refetchOnMount: 'always',
			enabled: !!user.userId,
			initialData: {
				total: 0,
				items: [],
			},
		}
	);

	useEffect(() => {
		queryClient.prefetchQuery(
			[
				'images',
				{
					page: page + 1,
					simplesearch,

					chosenCategory,
					sortType,
				},
			],
			getImages
		);
		//eslint-disable-next-line
	}, [page, queryClient, user.userId, chosenCategory]);

	useEffect(() => {
		setPage(1);
	}, [simplesearch, chosenCategory]);

	return (
		<>
			<div className="white-box white-box--selectors">
				<div className="white-box__inner">
					<h2 className="main-title main-title--25 hidden-md">
						Zamieszczone materiały
					</h2>
					<div className="main-text wysiwyg">
						<p>
							Poniżej znajdują się wszystkie materiały, które dodałeś w
							serwisie. <br />
							Możesz w tym miejscu dokonać globalnych operacji na zamieszczonych
							materiałach
						</p>
					</div>

					<div className="select-container">
						<div className="input-wrapper">
							<input
								onChange={(e) => setSimplesearch(e.target.value)}
								type="text"
								name="f-search"
								placeholder="Nr zestawu obrazów"
								className="form__input search-input required"
								id="f-search"
							/>
							<button>
								<i className="ico ico-search-icon"></i>
							</button>
						</div>

						<div className="select-container__additional">
							<div className="select">
								<Select
									disabled={!imageCategoriesQuery.data.length}
									className={'f-sort'}
									value={chosenCategory}
									onChange={(e) => {
										if (!e) return;
										setChosenCategory(CATEGORY_ALL.includes(e) ? '' : e);
									}}
									placeholder="Kategoria"
									data={imageCategoriesQuery.data}
									rightSection={<IconChevronDown size={10} />}
									rightSectionWidth={27}
									styles={(theme) => ({
										rightSection: {
											marginRight: 20,
											'& svg': {
												strokeWidth: 1,
											},
										},
										input: {
											backgroundColor: '#fff',
											border: '2px solid #BCD3E5',
											borderRadius: 0,
											transition: 'all 0.3s ease-out 0s',
											height: 57,
										},
										item: {
											'&[data-selected]': {
												backgroundColor: '#d0dae2',
												'&, &:hover': {
													backgroundColor: '#d0dae2',
													color: '#fff',
												},
											},
											'&[data-hovered]': {
												backgroundColor: '#364a59',
												color: '#fff',
											},
										},
									})}
								/>
							</div>
							<div className="select">
								<Select
									className={'f-sort'}
									onChange={setSortType}
									value={sortType}
									placeholder="Sortuj"
									data={[
										{
											value: 'desc',
											label: 'Data: malejąco',
										},
										{
											value: 'asc',
											label: 'Data: rosnąco',
										},
									]}
									rightSection={<IconChevronDown size={10} />}
									rightSectionWidth={27}
									styles={(theme) => ({
										rightSection: {
											marginRight: 20,
											'& svg': {
												strokeWidth: 1,
											},
										},
										input: {
											backgroundColor: '#fff',
											border: '2px solid #BCD3E5',
											borderRadius: 0,
											transition: 'all 0.3s ease-out 0s',
											height: 57,
										},
										item: {
											'&[data-selected]': {
												backgroundColor: '#d0dae2',
												'&, &:hover': {
													backgroundColor: '#d0dae2',
													color: '#fff',
												},
											},
											'&[data-hovered]': {
												backgroundColor: '#364a59',
												color: '#fff',
											},
										},
									})}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="white-box">
				{imagesQuery.isFetching && !imagesQuery.isFetched && (
					<div
						style={{ padding: 50, display: 'flex', justifyContent: 'center' }}
					>
						<Loader />
					</div>
				)}
				<div className="white-box__inner">
					<div className="uploaded-materials-table-wrapper">
						{imagesQuery.data?.items.length === 0 && imagesQuery.isFetched && (
							<>
								<p style={{ margin: 0, padding: 20 }}>
									Brak zamieszczonych materiałów.
								</p>
								<div className="form__actions mb-20 mt-40">
									<a
										onClick={(e) => {
											e.preventDefault();
											setChosenMenuItem('Dodaj materiał');
										}}
										href="/"
										className="btn"
									>
										<div>Dodaj swój pierwszy materiał</div>
									</a>
								</div>
							</>
						)}
						{imagesQuery.data?.items.length > 0 && (
							<table className="uploaded-materials-table">
								<thead>
									<tr>
										<th className="uploaded-materials-table__th"></th>
										<th className="uploaded-materials-table__th">NR ZESTAWU</th>
										<th className="uploaded-materials-table__th">
											NR OBRAZU W ZESTAWIE
										</th>
										<th className="uploaded-materials-table__th">
											DATA WYKONANIA
										</th>
										<th className="uploaded-materials-table__th">
											OPIS ZESTAWU
										</th>
										<th className="uploaded-materials-table__th">WIZERUNEK</th>
										<th className="uploaded-materials-table__th">
											WARUNKI UDOSTĘPNIENIA
										</th>
										<th className="uploaded-materials-table__th">STATUS</th>
										<th className="uploaded-materials-table__th">Opcje</th>
									</tr>
								</thead>
								<tbody>
									{imagesQuery.data?.items.map((img) => (
										<ImageGroupRow
											key={img.id}
											imageGroup={img.imageGroup}
											img={img}
											setPage={setPage}
											setChosenMenuItem={setChosenMenuItem}
										/>
									))}
								</tbody>
							</table>
						)}
					</div>
					{imagesQuery.data?.total / PER_PAGE > 1 && (
						<Pagination
							page={page}
							onChange={setPage}
							total={Math.ceil(imagesQuery.data?.total / 10)}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default PublishedItems;
