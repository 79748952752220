import React, { useState } from 'react';
import Login from '../Login/Login';
import Register from '../Register/Register';
import PasswordReminder from '../PasswordReminder/PasswordReminder';

const Authorization = (props) => {
	const [isRegister, setIsRegister] = useState(false);
	const [isPasswordReminder, setIsPasswordReminder] = useState(false);

	return (
		<div
			className="mp-flex-wrapper__left mp-flex-white js-mp-flex-white tab active login mp-flex-wrapper__small"
			id="tab1"
		>
			{!isRegister && !isPasswordReminder && (
				<Login
					setIsRegister={setIsRegister}
					setIsPasswordReminder={setIsPasswordReminder}
				/>
			)}
			{isRegister && !isPasswordReminder && (
				<Register setIsRegister={setIsRegister} />
			)}
			{isPasswordReminder && (
				<PasswordReminder setIsPasswordReminder={setIsPasswordReminder} />
			)}
		</div>
	);
};

export default Authorization;
