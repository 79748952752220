import React from 'react';
import { useDispatch } from 'react-redux';
import { newMaterialActions } from '../../store/slice/newMaterialSlice';

const AsideMenu = ({ setChosenMenuItem, chosenMenuItem }) => {
	const dispatch = useDispatch();
	return (
		<aside className="sidenav">
			<div className="sidenav-container hidden-md">
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Zamieszczone materiały' ? '' : 'btn--outline'
					}`}
					onClick={() => {
						dispatch(newMaterialActions.resetForm());
						setChosenMenuItem('Zamieszczone materiały');
					}}
				>
					Zamieszczone materiały
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Bieżące pobrania' ? '' : 'btn--outline'
					}`}
					onClick={() => {
						setChosenMenuItem('Bieżące pobrania');
					}}
				>
					Bieżące pobrania
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Historyczne pobrania' ? '' : 'btn--outline'
					}`}
					onClick={() => {
						setChosenMenuItem('Historyczne pobrania');
					}}
				>
					Historyczne pobrania
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Twoje dane' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Twoje dane')}
				>
					Twoje dane
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Zmiana hasła' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Zmiana hasła')}
				>
					Zmiana hasła
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Dokumenty księgowe' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Dokumenty księgowe')}
				>
					Dokumenty księgowe
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Powiadomienia' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Powiadomienia')}
				>
					Powiadomienia
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Korespondencja' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Korespondencja')}
				>
					Korespondencja
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Dokumenty informacyjne' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Dokumenty informacyjne')}
				>
					Dokumenty informacyjne
				</button>
			</div>

			<div className="sidenav-select">
				<div className="select">
					<select
						name="f-select"
						className="form__select js-select-default required select2-hidden-accessible"
						id="f-select"
						data-select2-id="f-select"
						tabIndex="-1"
						aria-hidden="true"
					>
						<option value="1" data-select2-id="2">
							Pobrane materiały
						</option>
						<option value="2">Twoje dane</option>
						<option value="3">Zmiana hasła</option>
						<option value="4">Faktury/Rachunki</option>
						<option value="5">Dokumenty</option>
						<option value="6">Powiadomienia</option>
					</select>
					<span
						className="select2 select2-container select2-container--default"
						dir="ltr"
						data-select2-id="1"
						style={{ width: 'auto' }}
					>
						<span className="selection">
							<span
								className="select2-selection select2-selection--single"
								role="combobox"
								aria-haspopup="true"
								aria-expanded="false"
								tabIndex="0"
								aria-labelledby="select2-f-select-container"
							>
								<span
									className="select2-selection__rendered"
									id="select2-f-select-container"
									role="textbox"
									aria-readonly="true"
									title="Pobrane materiały"
								>
									Pobrane materiały
								</span>
								<span className="select2-selection__arrow" role="presentation">
									<b role="presentation"></b>
								</span>
							</span>
						</span>
						<span className="dropdown-wrapper" aria-hidden="true"></span>
					</span>
				</div>
			</div>
		</aside>
	);
};

export default AsideMenu;
