import React, { useMemo, useState } from 'react';
import { Loader, Pagination } from '@mantine/core';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { PER_PAGE, getRecentPurchasedItemsByPublisher } from '../../api/get';
import Row from './Row';
import { formatDateForInput } from '../../utils';

const RecentDownloads = () => {
	const user = useSelector((state) => state.auth.user);

	const date = useMemo(() => {
		let today = new Date();
		if (today.getDate() > 15) today.setDate(15);
		else today.setDate(1);

		return formatDateForInput(today);
	}, []);

	const [page, setPage] = useState(1);

	const itemsQuery = useQuery(
		['purchase_items', page, user.userId, date],
		getRecentPurchasedItemsByPublisher,
		{
			initialData: {
				total: 0,
				items: [],
			},
		}
	);

	return (
		<>
			<div className="white-box white-box--selectors">
				<div className="white-box__inner">
					<h2 className="main-title main-title--25 hidden-md">
						Pobrania bieżące
					</h2>
					<div className="main-text wysiwyg">
						<p style={{ marginBottom: 0 }}>
							Poniżej znajdują się pobrania Twoich materiałów z ostatniego
							okresu rozliczeniowego.
						</p>
					</div>
				</div>
			</div>
			<div className="white-box">
				{itemsQuery.isFetching && !itemsQuery.data?.total && (
					<div
						style={{ padding: 50, display: 'flex', justifyContent: 'center' }}
					>
						<Loader />
					</div>
				)}
				<div className="white-box__inner">
					<div className="uploaded-materials-table-wrapper">
						{!itemsQuery.data?.total && itemsQuery.isFetched && (
							<>
								<p style={{ margin: 0, padding: 20 }}>
									Brak pobranych materiałów.
								</p>
							</>
						)}
						{itemsQuery.data?.items.length > 0 && (
							<table className="uploaded-materials-table">
								<thead>
									<tr>
										<th className="uploaded-materials-table__th">MINIATURKA</th>
										<th className="uploaded-materials-table__th">
											DATA POBRANIA
										</th>
										<th className="uploaded-materials-table__th">
											NR ZAPISU POBRANIA
										</th>
										<th className="uploaded-materials-table__th">
											KOD POBRANIA
										</th>
										<th className="uploaded-materials-table__th">
											NR ZESTAWU OBRAZÓW
										</th>
										<th className="uploaded-materials-table__th">
											NR OBRAZU Z ZESTAWU
										</th>
										<th className="uploaded-materials-table__th">
											KWOTA BRUTTO Z POBRANIA
										</th>
									</tr>
								</thead>
								<tbody>
									{itemsQuery.data?.items.map((item) => (
										<Row key={item.id} item={item} />
									))}
								</tbody>
							</table>
						)}
					</div>
					{itemsQuery.data?.total / PER_PAGE > 1 && (
						<Pagination
							page={page}
							onChange={setPage}
							total={Math.ceil(itemsQuery.data?.total / 10)}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default RecentDownloads;
