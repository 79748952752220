import React from 'react';

const Summary = ({setStep}) => {
    return (
        <div className="white-box white-box--larger">
            <div className="white-box__inner">

                <div className="center-container">
                    <div className="thank-you-title"><i className="ico ico-check-icon"></i> Dziękujemy za dodanie
                        materiału
                    </div>
                    <div className="thank-you-subtitle">Twój materiał został wysłany do menadżera serwisu i oczekuje na
                        akceptację.
                    </div>
                    <a
                        onClick={() => setStep(1)}
                        href="#" className="btn btn--min">+ Dodaj następny</a>
                </div>
            </div>
        </div>
    );
};

export default Summary;