import React, { useEffect, useState } from 'react';
import { FileInput, Loader, Tooltip } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { motion } from 'framer-motion';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { authorizedDeleteWithId } from '../../fetchFunctions';
import { newMaterialActions } from '../../store/slice/newMaterialSlice';
import valueComponent from './ValueComponent';
import JSZip from 'jszip';

const FirstStep = ({ setStep }) => {
	const dispatch = useDispatch();
	const form = useSelector((state) => state.newMaterial);
	const [imagesToDelete, setImagesToDelete] = useState([]);
	const [imagesUnzip, setImagesUnzip] = useState(false);
	const [invalidFields, setInvalidFields] = useState({
		title: false,
		publisherAuthor: false,
		publisherDescription: false,
	});
	const deleteImgMutation = useMutation(authorizedDeleteWithId);

	useEffect(() => {
		if (form.title) {
			setInvalidFields({
				title: false,
				publisherAuthor: false,
				publisherDescription: false,
			});
		}
	}, [form]);

	const handleSubmit = () => {
		const invalids = {
			title: !form.title,
			publisherAuthor: !form.publisherAuthor,
			publisherDescription: !form.publisherDescription,
		};

		if (Object.values(invalids).some((v) => !!v)) {
			setInvalidFields(invalids);
			return;
		}

		setStep((prevState) => prevState + 1);
	};

	return (
		<div className="col-lg-12 col-md-6">
			<h3 className="main-title main-title--25 main-title--mb-45">
				Uzupełnij opis zestawu
			</h3>
			<div
				style={{
					display: 'flex',
					gap: 50,
					alignItems: imagesUnzip ? 'center' : 'flex-start',
				}}
			>
				<form style={{ maxWidth: 391, width: '100%' }}>
					<div className="form__group">
						<div className="form__group">
							<div className="form__field">
								<input
									onChange={(e) => {
										dispatch(
											newMaterialActions.updateForm({ title: e.target.value })
										);
									}}
									defaultValue={form?.title}
									type="text"
									name="f-author"
									placeholder="Tytuł grupy zdjęć"
									className="form__input required"
									id="f-author"
								/>
							</div>
						</div>
						<div className="form__field">
							<div className="input-container">
								<DatePicker
									defaultValue={
										form?.publisherImageGroupCreatedAt
											? new Date(form?.publisherImageGroupCreatedAt)
											: null
									}
									locale="pl"
									allowFreeInput
									dateParser={(dateString) => {
										let parts = dateString.split('.');
										let formattedDateString =
											parts[1] + '/' + parts[0] + '/' + parts[2];
										return new Date(Date.parse(formattedDateString));
									}}
									inputFormat="DD.MM.YYYY"
									placeholder="Data wykonania zestawu"
									styles={(theme) => ({
										placeholder: {
											color: '#333333',
										},
										input: {
											backgroundColor: '#fff',
											border: '2px solid #BCD3E5',
											borderRadius: 0,
											transition: 'all 0.3s ease-out 0s',
											height: 57,
											color: '#333333',
										},
										item: {
											// applies styles to selected item
											'&[data-selected]': {
												backgroundColor: '#d0dae2',
												'&, &:hover': {
													backgroundColor: '#d0dae2',
													color: '#fff',
												},
											},

											// applies styles to hovered item (with mouse or keyboard)
											'&[data-hovered]': {
												backgroundColor: '#364a59',
												color: '#fff',
											},
										},
										rightSection: {
											pointerEvents: 'none',
										},
									})}
									onChange={(d) => {
										dispatch(
											newMaterialActions.updateForm({
												publisherImageGroupCreatedAt: d?.getTime(),
											})
										);
									}}
									rightSection={
										<a className="floating-icon">
											<i className="ico ico-calendar-icon"> </i>
										</a>
									}
								/>
							</div>
						</div>
					</div>
					<div className="form__group">
						<div className="form__field">
							<input
								onChange={(e) => {
									dispatch(
										newMaterialActions.updateForm({
											publisherAuthor: e.target.value,
										})
									);
								}}
								defaultValue={form?.publisherAuthor}
								type="text"
								name="f-author"
								placeholder="Autor"
								className="form__input required"
								id="f-author"
							/>
						</div>
					</div>
					<div className="form__group">
						<div className="form__field">
							<div className="input-container">
								<input
									defaultValue={form?.publisherGodMotherDescription}
									onChange={(e) => {
										dispatch(
											newMaterialActions.updateForm({
												publisherGodMotherDescription: e.target.value,
											})
										);
									}}
									type="text"
									name="f-image"
									placeholder="Wizerunek"
									className="form__input required"
									id="f-image"
								/>
								<Tooltip label="Powinieneś podać dane osoby, gdy na obrazach jest osoba publiczna, np. znany sportowiec, aktor, polityk, biznesmen">
									<a
										href="/"
										className="floating-icon js-tooltip tooltipstered"
									>
										<i className="ico ico-tooltip">
											<span className="path1"></span>
											<span className="path2"></span>
										</i>
									</a>
								</Tooltip>
							</div>
						</div>
					</div>
					<div className="form__group">
						<div className="form__field">
							<textarea
								defaultValue={form?.publisherDescription}
								onChange={(e) => {
									dispatch(
										newMaterialActions.updateForm({
											publisherDescription: e.target.value,
										})
									);
								}}
								name="f-description"
								rows={7}
								required
								className="form__textarea required"
								id="f-description"
								placeholder="Opis"
								maxLength={120}
							></textarea>
						</div>
					</div>
					<div className="form__actions">
						<FileInput
							valueComponent={valueComponent}
							multiple
							defaultValue={form?.imageUrl}
							styles={(theme) => ({
								rightSection: {
									marginRight: 20,
									'& svg': {
										strokeWidth: 1,
									},
								},
								placeholder: {
									color: '#000 !important',
									fontSize: 14,
									fontWeight: 600,
									fontFamily: `"Open Sans", sans-serif`,
								},
								input: {
									backgroundColor: '#E6F0F7',
									border: '2px solid #BCD3E5',
									borderRadius: 0,
									transition: 'all 0.3s ease-out 0s',
									height: 57,
									textAlign: 'center',
									color: '#000',
									fontSize: 14,
									fontWeight: 600,
									fontFamily: `"Open Sans", sans-serif`,
								},
								icon: {
									left: 40,
								},
								item: {
									'&[data-selected]': {
										backgroundColor: '#d0dae2',
										'&, &:hover': {
											backgroundColor: '#d0dae2',
											color: '#fff',
										},
									},
									'&[data-hovered]': {
										backgroundColor: '#364a59',
										color: '#fff',
									},
								},
							})}
							icon={<i className="ico ico-library-icon"></i>}
							placeholder={'Dodaj obrazy (Format JPG lub ZIP)'}
							accept="image/jpeg, .zip"
							value={form.imageUrl}
							onChange={(e) => {
								if (
									![
										'image/jpeg',
										'application/zip',
										'application/x-zip-compressed',
									].includes(e[0].type)
								)
									return;

								if (e[0].type === 'image/jpeg') {
									setImagesUnzip(true);
									e.forEach((img) => {
										dispatch(
											newMaterialActions.updateImages({ imageUrl: img })
										);
									});
									setTimeout(() => {
										setImagesUnzip(false);
									}, 2000);
									return;
								}

								setImagesUnzip(true);
								const new_zip = new JSZip();
								new_zip
									.loadAsync(e[0])
									.then(function (zip) {
										Object.values(zip.files).forEach((f, i) => {
											if (f.name.startsWith('__MACOSX')) {
												return;
											}
											if (f.dir) {
												return;
											}
											if (!f.name.match(/\.(jpg|jpeg|png)$/i)) {
												return;
											}
											zip.files[f.name].async('uint8array').then((data) => {
												if (f.name[0] === '.') {
													return;
												}
												const file = new File([data.buffer], `image-${i}.png`, {
													type: 'image/png',
												});
												dispatch(
													newMaterialActions.updateImages({ imageUrl: file })
												);
											});
										});
									})
									.then(() => {
										setTimeout(() => {
											setImagesUnzip(false);
										}, 2000);
									});
							}}
						/>
					</div>
					{form.imageUrl.length > 0 && (
						<div className="form__actions">
							<p className="form__actions--info">
								Liczba dodanych obrazów: {form?.imageUrl?.length}
							</p>
						</div>
					)}

					<div className="form__actions">
						<span onClick={handleSubmit} className="btn btn--block">
							Przejdź dalej
						</span>
					</div>
					{invalidFields.title && (
						<motion.p
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							style={{ color: 'red', marginTop: 10, marginBottom: 0 }}
						>
							Proszę wpisać tytuł.
						</motion.p>
					)}
					{invalidFields.publisherAuthor && (
						<motion.p
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							style={{ color: 'red', marginTop: 10, marginBottom: 0 }}
						>
							Proszę wpisać autora.
						</motion.p>
					)}
					{invalidFields.publisherDescription && (
						<motion.p
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							style={{ color: 'red', marginTop: 10, marginBottom: 0 }}
						>
							Proszę wpisać opis.
						</motion.p>
					)}
				</form>
				{imagesUnzip && (
					<div
						style={{
							margin: '0 auto',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<div>
							<Loader size={44} />
						</div>
					</div>
				)}
				{!imagesUnzip && (
					<div className="form__thumbnails--container">
						<div className="form__thumbnails">
							{form?.images?.length > 0 && (
								<h5 style={{ marginBottom: 0, fontSize: 16 }}>
									Zdjęcia, które akutalnie znajdują się w zestawie
								</h5>
							)}
							{form?.images?.map((img) => {
								return (
									<div
										key={img.id}
										className={`form__thumbnails--item ${
											imagesToDelete.includes(img) ? 'chosen' : ''
										}`}
									>
										<img
											onClick={() => {
												setImagesToDelete((prev) => {
													if (prev.includes(img)) {
														return prev.filter((e) => e !== img);
													} else {
														return [...prev, img];
													}
												});
											}}
											src={img?.originalImage?.contentUrl}
											alt="preview"
										/>
										<div
											onClick={() => {
												deleteImgMutation.mutate(
													{
														url: '/images',
														id: img.id,
													},
													{
														onSuccess: () => {
															dispatch(newMaterialActions.daleteFormImage(img));
														},
													}
												);
											}}
											className={'img-delete'}
										>
											&#x2715;
										</div>
									</div>
								);
							})}
							{form.imageUrl.length > 0 && form?.images?.length > 0 && (
								<h5 style={{ marginBottom: 0, fontSize: 16 }}>
									Zdjęcia, które zostaną dodane do zestawu
								</h5>
							)}
							{form?.imageUrl.map((img) => {
								const thumbnail = URL.createObjectURL(img);
								return (
									<div
										className={`form__thumbnails--item ${
											imagesToDelete.includes(img) ? 'chosen' : ''
										}`}
									>
										<img
											onClick={() => {
												setImagesToDelete((prev) => {
													if (prev.includes(img)) {
														return prev.filter((e) => e !== img);
													} else {
														return [...prev, img];
													}
												});
											}}
											src={thumbnail}
											alt="preview"
										/>
										<div
											onClick={() => {
												dispatch(newMaterialActions.daleteImage(img));
											}}
											className={'img-delete'}
										>
											&#x2715;
										</div>
									</div>
								);
							})}
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{form.imageUrl.length > 0 && imagesToDelete.length === 0 && (
								<motion.span
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									onClick={() => {
										setImagesToDelete([]);
										setTimeout(() => {
											dispatch(newMaterialActions.daleteAllImages());
											dispatch(newMaterialActions.daleteAllFormImages());
										}, 100);
									}}
									className="btn btn--danger"
								>
									Usuń wszystkie
								</motion.span>
							)}
							{form.imageUrl.length > 0 && imagesToDelete.length > 0 && (
								<motion.span
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									onClick={() => {
										imagesToDelete.forEach((img) => {
											if (img.id) {
												deleteImgMutation.mutate(
													{
														url: '/images',
														id: img.id,
													},
													{
														onSuccess: () => {
															dispatch(newMaterialActions.daleteFormImage(img));
														},
													}
												);
											} else {
												dispatch(newMaterialActions.daleteImage(img));
											}
										});
										setImagesToDelete([]);
									}}
									className="btn btn--danger"
								>
									Usuń zaznaczone
								</motion.span>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FirstStep;
