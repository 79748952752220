import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    categories: [
        {
            id: 1,
            title: "Wszystkie"
        },
        {
            id: 2,
            title: "Krajobrazy"
        },
        {
            id: 3,
            title: "Ludzie"
        },
        {
            id: 4,
            title: "Zwierzęta"
        }
    ],
    items: [
        {
            id: 1,
            downloadCode: "XXX-1",
            image: {
                id: 1,
                category: "Krajobrazy",
                createdAt: "2022-09-28T14:15:22Z",
                url: "https://test.api.gettimages.testujemyweby.pl/uploads//62f4cbed61dd7346010062.jpg",
                siteUrl : "https://test.client.gettimages.testujemyweby.pl/photos/1ed17da6-0191-637e-83e3-7fbf9551da55/1ed17dab-b51a-6756-837f-517a373a3b34",
                imageGroup: {
                    id: "uxovRq7zwDJt2K8H",
                    insideId: "XYZ",
                    title: "Kotek-1",
                    status: 0,
                    publisherDescription: "Piękne zdjęcia krajobrazowe",
                    finalDescription: "Piękne zdjęcia krajobrazowe",
                    publisherAuthor: "Nieznany",
                    finalAuthor: "Firma Test",
                    publisherGodMotherDescription: "string",
                    finalGodMotherDescription: "string",
                    publisherName: "Jacek Kowalski",
                    publisherPricingGrossSimplified: 0,
                    finalPricingGrossSimplified: 0,
                    publisherPricingGrossMedia: 0,
                    finalPricingGrossMedia: 0,
                    publisherPricingGrossCommercial: 0,
                    finalPricingGrossCommercial: 0,
                    publisherOrderIncomePercent: 100,
                    finalOrderIncomePercent: 100,
                    publisherImageGroupCreatedAt: "2019-06-24T14:15:22Z",
                    finalImageGroupCreatedAt: "2019-08-24T14:15:22Z",
                    publisher: "словник",
                },
                price: 1000
            },
        },
        {
            id: 2,
            downloadCode: "XXX-2",
            image: {
                id: 1,
                category: "Krajobrazy",
                createdAt: "2022-09-28T14:15:22Z",
                url: "https://test.api.gettimages.testujemyweby.pl/uploads//62f4cbe57d40d427814317.jpg",
                siteUrl : "https://test.client.gettimages.testujemyweby.pl/photos/1ed17da6-0191-637e-83e3-7fbf9551da55/1ed17daf-9e13-6964-afc8-b35393512387",
                imageGroup: {
                    id: "Tq9LiU2hcgpszkVy",
                    insideId: "XYZ",
                    title: "Kotek-1",
                    status: 0,
                    publisherDescription: "Piękne zdjęcia krajobrazowe",
                    finalDescription: "Piękne zdjęcia krajobrazowe",
                    publisherAuthor: "Nieznany",
                    finalAuthor: "Firma Test",
                    publisherGodMotherDescription: "string",
                    finalGodMotherDescription: "string",
                    publisherName: "Jacek Kowalski",
                    publisherPricingGrossSimplified: 0,
                    finalPricingGrossSimplified: 0,
                    publisherPricingGrossMedia: 0,
                    finalPricingGrossMedia: 0,
                    publisherPricingGrossCommercial: 0,
                    finalPricingGrossCommercial: 0,
                    publisherOrderIncomePercent: 100,
                    finalOrderIncomePercent: 100,
                    publisherImageGroupCreatedAt: "2019-06-24T14:15:22Z",
                    finalImageGroupCreatedAt: "2019-08-24T14:15:22Z",
                    publisher: "словник",
                },
                price: 1000
            },
        },
        {
            id: 3,
            downloadCode: "XXX-3",
            image: {
                id: 1,
                category: "Ludzie",
                createdAt: "2022-09-28T14:15:22Z",
                url: "https://test.api.gettimages.testujemyweby.pl/uploads//62f4cbf00d7f5270383676.jpg",
                siteUrl : "https://test.client.gettimages.testujemyweby.pl/photos/1ed17d81-0a2f-664a-acfc-f7c27aa99e44/1ed17d82-ea0e-6c10-ab81-7315fb726c1b",
                imageGroup: {
                    id: "Tq9LiU2hcgpszkVy",
                    insideId: "XYZ",
                    title: "Kotek-1",
                    status: 0,
                    publisherDescription: "Piękne zdjęcia",
                    finalDescription: "Piękne zdjęcia",
                    publisherAuthor: "Nieznany",
                    finalAuthor: "Firma Test",
                    publisherGodMotherDescription: "string",
                    finalGodMotherDescription: "string",
                    publisherName: "Jacek Kowalski",
                    publisherPricingGrossSimplified: 0,
                    finalPricingGrossSimplified: 0,
                    publisherPricingGrossMedia: 0,
                    finalPricingGrossMedia: 0,
                    publisherPricingGrossCommercial: 0,
                    finalPricingGrossCommercial: 0,
                    publisherOrderIncomePercent: 100,
                    finalOrderIncomePercent: 100,
                    publisherImageGroupCreatedAt: "2019-06-24T14:15:22Z",
                    finalImageGroupCreatedAt: "2019-08-24T14:15:22Z",
                    publisher: "словник",
                },
                price: 1000
            },
        },
        {
            id: 4,
            downloadCode: "XXX-4",
            image: {
                id: 1,
                category: "Zwierzęta",
                createdAt: "2022-09-28T14:15:22Z",
                url: "https://test.api.gettimages.testujemyweby.pl/uploads//62f4cbf1c1f3f801948136.jpg",
                siteUrl : "https://test.client.gettimages.testujemyweby.pl/photos/1ed17d45-8a17-6a74-8a6a-dba6fca3287d/1ed17d47-3d52-6f16-be17-9d7f3dc284d7",
                imageGroup: {
                    id: "f3HC1XVxuZ7e0wo4",
                    insideId: "XYZ",
                    title: "Kotek-1",
                    status: 0,
                    publisherDescription: "Piękne zdjęcia zwierząt",
                    finalDescription: "Piękne zdjęcia zwierząt",
                    publisherAuthor: "Nieznany",
                    finalAuthor: "Firma Test",
                    publisherGodMotherDescription: "string",
                    finalGodMotherDescription: "string",
                    publisherName: "Jacek Kowalski",
                    publisherPricingGrossSimplified: 0,
                    finalPricingGrossSimplified: 0,
                    publisherPricingGrossMedia: 0,
                    finalPricingGrossMedia: 0,
                    publisherPricingGrossCommercial: 0,
                    finalPricingGrossCommercial: 0,
                    publisherOrderIncomePercent: 100,
                    finalOrderIncomePercent: 100,
                    publisherImageGroupCreatedAt: "2019-06-24T14:15:22Z",
                    finalImageGroupCreatedAt: "2019-08-24T14:15:22Z",
                    publisher: "словник",
                },
                price: 1000
            },
        },
    ]
};

const librarySlice = createSlice({
    name: "library",
    initialState,
    reducers: {
        addCategory(state, action) {
            state.categories = [...state.categories, action.payload]
        },
        changeImgCategory(state, action) {
            const id = action.payload.id;
            const chosenImg = state.items.find(e => e.id === id)
            chosenImg.image.category = action.payload.category
            state.items[id-1] = chosenImg
        },
        changeCatName(state,action) {
          const chosenCatIndex = state.categories.findIndex(c=> c.title === action.payload.cat)
          const chosenCat = state.categories.find(c=> c.title === action.payload.cat)
            chosenCat.title = action.payload.title
            state.categories[chosenCatIndex] = chosenCat
        },
        deleteItem(state, action) {
            const id = action.payload;
            state.items = state.items.filter(i => i.id !== id)
        },
        deleteCat(state, action) {
            const id = action.payload;
            state.categories = state.categories.filter(i => i.id !== id)
        }
    }
})


export const libraryActions = librarySlice.actions;

export default librarySlice.reducer;