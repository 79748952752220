import React from 'react';
import { motion } from 'framer-motion';
import { showNotification } from '@mantine/notifications';
import { libraryActions } from '../../store/slice/librarySlice';
import { useDispatch } from 'react-redux';

const LibraryItem = ({ purchase, setChosenImg, setOpened }) => {
	const dispatch = useDispatch();

	const download = (e) => {
		fetch(e, {
			method: 'GET',
			headers: {},
		})
			.then((response) => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'image.png');
					document.body.appendChild(link);
					link.click();
				});
			})
			.catch((err) => {});
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="materials materials--library"
		>
			<div className="materials__wrapper">
				<div className="materials__photo">
					<img
						style={{ maxWidth: 256, height: 195, objectFit: 'cover' }}
						src={purchase?.image?.url}
						alt="box_photo"
					/>
				</div>
				<div className="materials__info">
					<div className="materials__info-item">
						<strong>Data wykonania:</strong>{' '}
						{new Date(purchase.image.createdAt).toLocaleDateString()}
					</div>
					<div className="materials__info-item">
						<strong>Dostawca:</strong>
						{purchase.image.imageGroup.publisherAuthor}
					</div>
					<div className="materials__info-item">
						<strong>Autor:</strong> {purchase.image.imageGroup.finalAuthor}
					</div>
					<div className="materials__info-item">
						<strong>Opis:</strong> {purchase.image.imageGroup.finalDescription}
					</div>
					<div className="materials__info-item">
						<strong>Warunki pobrania:</strong> -
					</div>
					<div className="materials__info-item">
						<strong>Opłata licencyjna brutto:</strong> 1234,82 zł
					</div>
				</div>

				<div className="materials__download">
					<a
						onClick={() => {
							setChosenImg(purchase);
							setOpened(true);
						}}
						href="#"
						className="materials__download-button"
					>
						{' '}
						<i className="ico ico-change-icon"></i> Przenieś do innej kategorii
					</a>
					<a
						onClick={() => {
							showNotification({
								title: 'Dodano do koszyka!',
								message: `Obrazek ${purchase.image.imageGroup.title} został dodany do Twojego koszyka!`,
								style: { padding: 30 },
							});
						}}
						href="#"
						className="materials__download-button"
					>
						{' '}
						<i className="ico ico-basket-icon"></i> Dodaj do koszyka
					</a>
					<a
						onClick={() => download(purchase.image.url)}
						href="#"
						className="materials__download-button"
					>
						{' '}
						<i className="ico ico-download2-icon"></i> Pobierz obraz
					</a>
					<a
						target="_blank"
						href={purchase.image.siteUrl}
						className="materials__download-button"
					>
						{' '}
						<i className="ico ico-image-icon"></i> Przejdź do podstrony z
						obrazem
					</a>
					<a
						onClick={() => {
							dispatch(libraryActions.deleteItem(purchase.id));
						}}
						href="#"
						className="materials__download-button"
					>
						{' '}
						<i className="ico ico-trash-icon"></i> Usuń z biblioteki
					</a>
				</div>
			</div>
		</motion.div>
	);
};

export default LibraryItem;
