import { Loader, Modal } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { authorizedPatchWithId } from '../../fetchFunctions';
import useInput from '../../hooks/useInput';
import { validatePassword } from '../../utils';
import PasswordCheck from './PasswordCheck';

const PasswordChange = () => {
	const [openModal, setOpenModal] = useState(false);
	const passwordChangeMutation = useMutation(authorizedPatchWithId);
	const user = useSelector((state) => state.auth.user);
	const {
		value: enteredPassword,
		enteredValueChangeHandler: enteredPasswordChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredNewPassword,
		isTouched: enteredNewPasswordIsTouched,
		enteredValueChangeHandler: enteredNewPasswordChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredRepeatedNewPassword,
		isTouched: enteredRepeatedNewPasswordIsTouched,
		enteredValueChangeHandler: enteredRepeatedNewPasswordChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const passwordChangeHandler = (e) => {
		e.preventDefault();
		passwordChangeMutation.mutate({
			url: `/publishers/${user.userId}/change_password`,
			data: {
				password: enteredNewPassword,
				current_password: enteredPassword,
			},
		});
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<div className="passwords">
					<h2 className="main-title main-title--25">Zmiana hasła</h2>

					<form onSubmit={passwordChangeHandler}>
						<div className="form__group">
							<div className="form__field">
								<input
									onChange={(e) => enteredPasswordChangeHandler(e)}
									type="password"
									name="f-current-password"
									placeholder="Aktualne hasło"
									className="form__input required"
									id="f-current-password"
								/>
							</div>
						</div>
						<div className="form__group">
							<div className="form__field">
								<div className="input-container">
									<input
										onChange={(e) => enteredNewPasswordChangeHandler(e)}
										type="password"
										name="f-new-password"
										placeholder="Nowe hasło"
										className="form__input required"
										id="f-new-password"
									/>
								</div>
							</div>
						</div>
						<div className="form__group">
							<div className="form__field">
								<input
									onChange={(e) => enteredRepeatedNewPasswordChangeHandler(e)}
									type="password"
									name="f-confirm-password"
									placeholder="Powtórz nowe hasło"
									className="form__input required"
									id="f-confirm-password"
								/>
								{enteredNewPassword !== enteredRepeatedNewPassword &&
									enteredNewPasswordIsTouched &&
									enteredRepeatedNewPasswordIsTouched && (
										<motion.span
											initial={{ opacity: 0, y: 5 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{
												default: {
													duration: 0.3,
												},
											}}
											className="form__info"
											style={{ color: 'red' }}
										>
											Hasła muszą być takie same!
										</motion.span>
									)}
								<span className="form__info">
									Hasło musi zawierać minimum 8 znaków, w tym jedną cyfrę.
								</span>
							</div>
						</div>
						<PasswordCheck
							enteredNewPassword={enteredNewPassword}
							enteredNewPasswordIsTouched={enteredNewPasswordIsTouched}
						/>

						<div className="form__actions">
							<button
								onClick={(e) => {
									passwordChangeHandler(e);
								}}
								disabled={
									enteredNewPassword !== enteredRepeatedNewPassword ||
									!validatePassword(enteredNewPassword)
								}
								type={'button'}
								className="btn btn--block"
							>
								{passwordChangeMutation.isLoading ? (
									<div>
										<Loader size={12} color={`#ffffff`} />
									</div>
								) : (
									<div>Zapisz zmiany</div>
								)}
							</button>
						</div>
						{passwordChangeMutation.isSuccess && (
							<motion.div
								initial={{ opacity: 0, y: 5 }}
								animate={{ opacity: 1, y: 0 }}
								className="form__actions mb-20"
							>
								<p style={{ textAlign: 'center', color: 'green' }}>
									Hasło zostało zmienione.
								</p>
							</motion.div>
						)}
						{passwordChangeMutation.isError && (
							<div className="form__actions">
								<motion.p
									initial={{ opacity: 0, y: 5 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{
										default: {
											duration: 0.3,
										},
									}}
									className="error"
								>
									Podane aktualne hasło jest nieprawidłowe!
								</motion.p>
							</div>
						)}
					</form>
				</div>
			</div>
			<Modal
				opened={openModal}
				withCloseButton={false}
				centered
				onClose={() => setOpenModal(false)}
				size="35%"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a href="/" className="modal__close js-modal-close">
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">
							Po zapisaniu zmian zostaniesz wylogowany!
						</h3>
						<p>Zaloguj się używając nowego hasła!</p>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
								gap: 20,
							}}
						>
							<a
								href="/"
								onClick={(e) => {
									passwordChangeHandler(e);
									setTimeout(() => setOpenModal(false), 1000);
								}}
								className="sidenav__item sidenav__item--add-category btn btn--primary"
							>
								Zapisz
							</a>
							<a
								href="/"
								onClick={() => {
									setOpenModal(false);
								}}
								className="sidenav__item sidenav__item--add-category btn btn--danger"
							>
								Anuluj
							</a>
						</div>
					</div>
				</div>
			</Modal>
		</motion.div>
	);
};

export default PasswordChange;
