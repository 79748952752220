import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from '../../pages/Main/Main';
import { authActions } from '../../store/slice/authSlice';
import { clearTokens } from '../../utils';
import Authorization from '../Authotization/Authorization';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Loading from '../Loading/Loading';
import SetPassword from '../PasswordReminder/SetPassword';
import RegisterSuccess from '../Register/RegisterSuccess';
import SubHeader from '../SubHeader/SubHeader';
import Unauthorized from './Unauthorized';

const Base = () => {
	const user = useSelector((state) => state?.auth?.user);
	const dispatch = useDispatch();

	const [chosenMenuItem, setChosenMenuItem] = useState(initialItem());

	useLayoutEffect(() => {
		(async () => {
			const refreshToken =
				Cookies.get('refreshToken') || localStorage.getItem('refreshToken');
			if (!refreshToken) return;

			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/auth/refresh_token`,
				{ refresh_token: refreshToken },
				{
					validateStatus: () => true,
				}
			);

			if (response.status >= 400) {
				clearTokens();
				return;
			}

			const { token, refresh_token } = response.data;

			const decoded = jwt(token);

			if (
				!decoded?.isActive ||
				decoded?.isBlocked ||
				decoded?.accountType !== 'publisher'
			) {
				clearTokens();
				return;
			}

			localStorage.setItem('token', token);
			localStorage.setItem('refreshToken', refresh_token);
			Cookies.set('token', token, {
				secure: true,
				expires: 360,
				domain: '.testujemyweby.pl',
			});

			Cookies.set('refreshToken', refresh_token, {
				secure: true,
				expires: 360,
				domain: '.testujemyweby.pl',
			});

			dispatch(authActions.logIn(decoded));
		})();
	}, []);

	if (
		(Cookies.get('refreshToken') || localStorage.getItem('refreshToken')) &&
		!user
	)
		return <Loading />;

	return (
		<>
			<Header setChosenMenuItem={setChosenMenuItem} />

			{user === null && (
				<Unauthorized>
					<BrowserRouter>
						<Routes>
							<Route index element={<Authorization />} />
							<Route path="/set-password/:token" element={<SetPassword />} />
							<Route path="/login" element={<RegisterSuccess />} />
							<Route path="*" element={<Authorization />} />
						</Routes>
					</BrowserRouter>
				</Unauthorized>
			)}
			{user && (
				<>
					<SubHeader setChosenMenuItem={setChosenMenuItem} />
					<Main
						setChosenMenuItem={setChosenMenuItem}
						chosenMenuItem={chosenMenuItem}
					/>
				</>
			)}
			<Footer />
		</>
	);
};

function initialItem() {
	let item = Cookies.get('redirect');
	Cookies.remove('redirect', {
		domain: '.testujemyweby.pl',
		path: '/',
	});

	item ??= 'Pobrane materiały';

	return item;
}

export default Base;
